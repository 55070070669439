// src/components/ImageUploader.js
import React, { useState } from 'react';

const ImageUploader = ({ onImageUpload }) => {
    const [image, setImage] = useState(null);

    const handleImageChange = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result);
            onImageUpload(reader.result); // Call the upload function with the new image
        };
        reader.readAsDataURL(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            handleImageChange(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Prevent default to allow drop
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
                border: '2px dashed #ccc',
                borderRadius: '5px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                marginBottom: '20px',
            }}
        >
            <h5>Drag and Drop Image Here</h5>
            <p>or</p>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e.target.files[0])}
                style={{ display: 'none' }} // Hide the file input
                id="file-upload"
            />
            <label htmlFor="file-upload" style={{ marginBottom: '10px', backgroundColor: '#6200ea', color: '#ffffff', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer' }}>
                Click to Upload
            </label>
            {/* {image && <img src={image} alt="Uploaded" style={{ width: '100%', height: 'auto', marginTop: '10px' }} />} */}
        </div>
    );
};

export default ImageUploader;
