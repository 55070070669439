import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { fabric } from 'fabric';

const CANVAS_SIZE = 750;

const Canvas = forwardRef(({ layers, showGrid = true, backgroundColor = 'transparent', onSelectionChange }, ref) => {
    const canvasRef = useRef(null);
    const fabricCanvasRef = useRef(null);
    const layerRefs = useRef(new Map());

    useEffect(() => {
        fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
            backgroundColor: backgroundColor,
            width: CANVAS_SIZE,
            height: CANVAS_SIZE,
            preserveObjectStacking: true,
            controlsAboveOverlay: true
        });

        // Set selection styles
        fabric.Object.prototype.set({
            borderColor: '#2196F3',
            cornerColor: '#2196F3',
            cornerSize: 8,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5
        });

        fabricCanvasRef.current.on('selection:created', (e) => {
            if (e.selected && e.selected[0] && onSelectionChange) {
                onSelectionChange(e.selected[0].id);
            }
        });

        fabricCanvasRef.current.on('selection:cleared', () => {
            if (onSelectionChange) {
                onSelectionChange(null);
            }
        });

        fabricCanvasRef.current.on('object:moving', handleObjectMoving);
        fabricCanvasRef.current.on('object:modified', handleObjectMoving);
        fabricCanvasRef.current.on('object:rotating', handleObjectRotating);

        return () => {
            fabricCanvasRef.current.dispose();
        };
    }, []);

    useEffect(() => {
        if (fabricCanvasRef.current) {
            fabricCanvasRef.current.setBackgroundColor(backgroundColor, () => {
                fabricCanvasRef.current.renderAll();
            });
        }
    }, [backgroundColor]);

    const handleObjectRotating = (e) => {
        if (!e.target) return;
        
        const obj = e.target;
        const wrappedObjects = fabricCanvasRef.current.getObjects().filter(o => o.wrapped && o.parentId === obj.id);
        
        wrappedObjects.forEach(wrapped => {
            wrapped.set({
                angle: obj.angle,
                originX: 'center',
                originY: 'center'
            });
            wrapped.setCoords();
        });

        fabricCanvasRef.current.renderAll();
    };

    const handleObjectMoving = (e) => {
        if (!e.target) return;
        
        const obj = e.target;
        const wrappedObjects = fabricCanvasRef.current.getObjects().filter(o => o.wrapped && o.parentId === obj.id);
        
        wrappedObjects.forEach(wrapped => {
            fabricCanvasRef.current.remove(wrapped);
        });

        const bounds = obj.getBoundingRect(true);
        const wrappedPositions = [];

        const centerX = bounds.left + bounds.width / 2;
        const centerY = bounds.top + bounds.height / 2;

        const overflowRight = bounds.left + bounds.width > CANVAS_SIZE;
        const overflowLeft = bounds.left < 0;
        const overflowBottom = bounds.top + bounds.height > CANVAS_SIZE;
        const overflowTop = bounds.top < 0;

        if (overflowRight) wrappedPositions.push({ left: centerX - CANVAS_SIZE, top: centerY });
        if (overflowLeft) wrappedPositions.push({ left: centerX + CANVAS_SIZE, top: centerY });
        if (overflowBottom) wrappedPositions.push({ left: centerX, top: centerY - CANVAS_SIZE });
        if (overflowTop) wrappedPositions.push({ left: centerX, top: centerY + CANVAS_SIZE });

        if (overflowRight && overflowBottom) wrappedPositions.push({ left: centerX - CANVAS_SIZE, top: centerY - CANVAS_SIZE });
        if (overflowRight && overflowTop) wrappedPositions.push({ left: centerX - CANVAS_SIZE, top: centerY + CANVAS_SIZE });
        if (overflowLeft && overflowBottom) wrappedPositions.push({ left: centerX + CANVAS_SIZE, top: centerY - CANVAS_SIZE });
        if (overflowLeft && overflowTop) wrappedPositions.push({ left: centerX + CANVAS_SIZE, top: centerY + CANVAS_SIZE });

        const wrappedCopies = wrappedPositions.map(pos => {
            const wrapped = fabric.util.object.clone(obj);
            wrapped.set({
                left: pos.left,
                top: pos.top,
                angle: obj.angle,
                scaleX: obj.scaleX,
                scaleY: obj.scaleY,
                flipX: obj.flipX,
                flipY: obj.flipY,
                selectable: false,
                hasControls: false,
                wrapped: true,
                parentId: obj.id,
                originX: 'center',
                originY: 'center',
                centeredRotation: true,
                visible: obj.visible !== false,
                opacity: obj.visible === false ? 0 : 1
            });
            
            wrapped.setCoords();
            fabricCanvasRef.current.add(wrapped);
            return wrapped;
        });

        if (obj.id) {
            layerRefs.current.set(obj.id, {
                original: obj,
                wrapped: wrappedCopies
            });
        }

        fabricCanvasRef.current.renderAll();
    };

    useEffect(() => {
        if (!fabricCanvasRef.current) return;

        const currentLayerIds = new Set(layers.map(layer => layer.id));

        Array.from(layerRefs.current.keys()).forEach(id => {
            if (!currentLayerIds.has(id)) {
                const layerRef = layerRefs.current.get(id);
                if (layerRef && layerRef.original) {
                    fabricCanvasRef.current.remove(layerRef.original);
                    if (layerRef.wrapped) {
                        layerRef.wrapped.forEach(wrapped => {
                            fabricCanvasRef.current.remove(wrapped);
                        });
                    }
                }
                layerRefs.current.delete(id);
            }
        });

        const existingObjects = new Map();
        fabricCanvasRef.current.getObjects().forEach(obj => {
            if (!obj.wrapped) {
                existingObjects.set(obj.id, obj);
                fabricCanvasRef.current.remove(obj);
            }
        });

        layers.forEach((layer, index) => {
            const existingObj = existingObjects.get(layer.id);
            
            if (existingObj) {
                existingObj.set({
                    flipX: layer.flipped,
                    visible: layer.visible !== false,
                    opacity: layer.visible === false ? 0 : 1,
                    selectable: layer.visible !== false
                });
                fabricCanvasRef.current.add(existingObj);
                if (layer.visible !== false) {
                    handleObjectMoving({ target: existingObj });
                }
            } else {
                fabric.Image.fromURL(layer.url, (img) => {
                    img.set({
                        id: layer.id,
                        left: layer.left,
                        top: layer.top,
                        scaleX: layer.scaleX * (layer.flipped ? -1 : 1),
                        scaleY: layer.scaleY,
                        selectable: layer.visible !== false,
                        hasControls: layer.visible !== false,
                        originalElement: true,
                        centeredRotation: true,
                        visible: layer.visible !== false,
                        opacity: layer.visible === false ? 0 : 1
                    });

                    fabricCanvasRef.current.add(img);
                    layerRefs.current.set(layer.id, {
                        original: img,
                        wrapped: []
                    });
                    if (layer.visible !== false) {
                        handleObjectMoving({ target: img });
                    }
                });
            }
        });

        fabricCanvasRef.current.renderAll();
    }, [layers]);

    const exportHighRes = () => {
        const canvas = fabricCanvasRef.current;
        if (!canvas) return null;

        const originalWidth = canvas.width;
        const originalHeight = canvas.height;
        const scaleFactor = 3600 / CANVAS_SIZE;

        canvas.setWidth(3600);
        canvas.setHeight(3600);
        
        canvas.getObjects().forEach(obj => {
            const originalLeft = obj.left;
            const originalTop = obj.top;
            const originalScaleX = obj.scaleX;
            const originalScaleY = obj.scaleY;

            obj.set({
                left: originalLeft * scaleFactor,
                top: originalTop * scaleFactor,
                scaleX: originalScaleX * scaleFactor,
                scaleY: originalScaleY * scaleFactor
            });
        });

        canvas.renderAll();

        const dataURL = canvas.toDataURL({
            format: 'png',
            quality: 1,
            multiplier: 1,
            enableRetinaScaling: true
        });

        canvas.setWidth(originalWidth);
        canvas.setHeight(originalHeight);

        canvas.getObjects().forEach(obj => {
            const originalLeft = obj.left / scaleFactor;
            const originalTop = obj.top / scaleFactor;
            const originalScaleX = obj.scaleX / scaleFactor;
            const originalScaleY = obj.scaleY / scaleFactor;

            obj.set({
                left: originalLeft,
                top: originalTop,
                scaleX: originalScaleX,
                scaleY: originalScaleY
            });
        });

        canvas.renderAll();

        const img = new Image();
        img.src = dataURL;

        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = 3600;
        tempCanvas.height = 3600;

        return new Promise((resolve) => {
            img.onload = () => {
                tempCtx.drawImage(img, 0, 0);
                const dpi = 300;
                tempCtx.setTransform(dpi / 72, 0, 0, dpi / 72, 0, 0);
                resolve(tempCanvas);
            };
        });
    };

    useImperativeHandle(ref, () => ({
        exportHighRes,
        fabricCanvasRef,
        getObjectState: (layerId) => {
            const objects = fabricCanvasRef.current.getObjects();
            const obj = objects.find(o => o.id === layerId && !o.wrapped);
            if (obj) {
                return {
                    scaleX: obj.scaleX * (obj.flipX ? -1 : 1),
                    scaleY: obj.scaleY,
                    left: obj.left,
                    top: obj.top,
                    visible: obj.visible
                };
            }
            return null;
        },
        selectObject: (layerId) => {
            if (!fabricCanvasRef.current) return;
            
            fabricCanvasRef.current.discardActiveObject();
            
            if (layerId) {
                const objects = fabricCanvasRef.current.getObjects();
                const objectToSelect = objects.find(obj => obj.id === layerId && !obj.wrapped);
                
                if (objectToSelect) {
                    fabricCanvasRef.current.setActiveObject(objectToSelect);
                }
            }
            
            fabricCanvasRef.current.requestRenderAll();
        }
    }));

    const gridSize = CANVAS_SIZE / 12;
    const gridLines = [];
    
    for (let i = 1; i < 12; i++) {
        gridLines.push(
            <line 
                key={`v${i}`}
                x1={i * gridSize} 
                y1={0} 
                x2={i * gridSize} 
                y2={CANVAS_SIZE} 
                stroke="#e0e0e0" 
                strokeWidth="0.5"
            />
        );
        gridLines.push(
            <line 
                key={`h${i}`}
                x1={0} 
                y1={i * gridSize} 
                x2={CANVAS_SIZE} 
                y2={i * gridSize} 
                stroke="#e0e0e0" 
                strokeWidth="0.5"
            />
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <canvas 
                ref={canvasRef} 
                style={{ 
                    width: `${CANVAS_SIZE}px`, 
                    height: `${CANVAS_SIZE}px`, 
                    backgroundColor: 'transparent' 
                }} 
            />
            {showGrid && (
                <svg 
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        pointerEvents: 'none'
                    }}
                    width={CANVAS_SIZE}
                    height={CANVAS_SIZE}
                >
                    {gridLines}
                </svg>
            )}
        </div>
    );
});

export default Canvas;