import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { BsGripVertical, BsEye, BsEyeSlash } from 'react-icons/bs';

const LayerManager = ({ 
    layers, 
    selectedLayerId, 
    onSelectLayer, 
    onDeleteLayer, 
    onDuplicateLayer, 
    onFlipLayer,
    onToggleVisibility 
}) => {
    const containerStyle = {
        padding: '10px',
        backgroundColor: '#1a1a1a',
        borderRadius: '4px',
        marginTop: '20px'
    };

    const layerItemStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '6px',
        marginBottom: '4px',
        backgroundColor: '#2a2a2a',
        borderRadius: '4px',
        cursor: 'pointer',
        gap: '8px',
        transition: 'all 0.2s ease'
    };

    const selectedLayerStyle = {
        backgroundColor: '#3a3a3a',
        boxShadow: '0 0 0 2px #2196F3'
    };

    const dragHandleStyle = {
        color: '#666',
        marginRight: '8px',
        cursor: 'grab',
        display: 'flex',
        alignItems: 'center'
    };

    const buttonStyle = {
        padding: '4px 8px',
        backgroundColor: 'transparent',
        border: '1px solid #666',
        color: '#fff',
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px'
    };

    const thumbnailStyle = {
        width: '30px',
        height: '30px',
        objectFit: 'contain',
        marginRight: '4px'
    };

    const buttonContainerStyle = {
        display: 'flex',
        gap: '4px',
        marginLeft: 'auto'
    };

    const visibilityButtonStyle = {
        background: 'transparent',
        border: 'none',
        color: '#fff',
        cursor: 'pointer',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '8px'
    };

    return (
        <div>
            <h2>Layers</h2>
            <Droppable droppableId="layer-list">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={containerStyle}
                    >
                        {layers.map((layer, index) => (
                            <Draggable
                                key={layer.id}
                                draggableId={layer.id}
                                index={index}
                            >
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{
                                            ...layerItemStyle,
                                            ...provided.draggableProps.style,
                                            ...(layer.id === selectedLayerId ? selectedLayerStyle : {})
                                        }}
                                        onClick={() => onSelectLayer(index)}
                                    >
                                        <div
                                            {...provided.dragHandleProps}
                                            style={dragHandleStyle}
                                        >
                                            <BsGripVertical />
                                        </div>
                                        <img
                                            src={layer.url}
                                            alt={`Layer ${index + 1}`}
                                            style={{
                                                ...thumbnailStyle,
                                                opacity: layer.visible === false ? 0.5 : 1
                                            }}
                                        />
                                        <span style={{ fontSize: '14px' }}>Layer {index + 1}</span>
                                        <div style={buttonContainerStyle}>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onToggleVisibility(index);
                                                }}
                                                style={visibilityButtonStyle}
                                                title={layer.visible === false ? "Show Layer" : "Hide Layer"}
                                            >
                                                {layer.visible === false ? <BsEyeSlash /> : <BsEye />}
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onFlipLayer(index);
                                                }}
                                                style={buttonStyle}
                                                title="Flip Horizontally"
                                            >
                                                ↔
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDuplicateLayer(index);
                                                }}
                                                style={buttonStyle}
                                                title="Duplicate Layer"
                                            >
                                                +
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDeleteLayer(index);
                                                }}
                                                style={buttonStyle}
                                                title="Delete Layer"
                                            >
                                                ×
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default LayerManager;