import React, { useState, useRef, useCallback, useEffect } from 'react';
import ImageUploader from './components/ImageUploader';
import Canvas from './components/Canvas';
import LayerManager from './components/LayerManager';
import EmojiPicker from './components/EmojiPicker';
import './App.css';
import logo from './assets/logo.png';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { saveAs } from 'file-saver';
import { StrictMode } from 'react';

const App = () => {
    const canvasRef = useRef(null);
    const [layers, setLayers] = useState([]);
    const [uploadedImages, setUploadedImages] = useState(new Set());
    const [showGrid, setShowGrid] = useState(true);
    const [canvasBackground, setCanvasBackground] = useState('transparent');
    const [selectedLayerId, setSelectedLayerId] = useState(null);

    const canvasSize = 750;

    useEffect(() => {
        if (canvasRef.current?.fabricCanvasRef?.current) {
            const fabricCanvas = canvasRef.current.fabricCanvasRef.current;
            
            fabricCanvas.on('selection:created', (e) => {
                if (e.selected && e.selected[0]) {
                    setSelectedLayerId(e.selected[0].id);
                }
            });

            fabricCanvas.on('selection:cleared', () => {
                setSelectedLayerId(null);
            });
        }
    }, [canvasRef.current]);

    const handleEmojiSelect = async (emojiData) => {
        const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
                <text x="50" y="50" font-size="70" text-anchor="middle" dominant-baseline="middle">
                    ${emojiData.emoji}
                </text>
            </svg>
        `;
        
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        const newLayer = {
            id: `layer-${Date.now()}`,
            url,
            flipped: false,
            scaleX: 1,
            scaleY: 1,
            left: (canvasSize - 100) / 2,
            top: (canvasSize - 100) / 2,
            angle: 0,
            zIndex: layers.length
        };

        setLayers(prevLayers => [...prevLayers, newLayer]);
    };

    const handleImageUpload = (url) => {
        if (!uploadedImages.has(url)) {
            setUploadedImages((prev) => new Set(prev).add(url));

            const imgElement = new Image();
            imgElement.src = url;
            imgElement.onload = () => {
                const scaleFactor = 0.3;
                const newWidth = canvasSize * scaleFactor;
                const newHeight = (imgElement.height / imgElement.width) * newWidth;

                const newLayer = {
                    id: `layer-${Date.now()}`,
                    url,
                    flipped: false,
                    scaleX: newWidth / imgElement.width,
                    scaleY: newHeight / imgElement.height,
                    left: (canvasSize - newWidth) / 2,
                    top: (canvasSize - newHeight) / 2,
                    angle: 0,
                    zIndex: layers.length
                };
                setLayers((prevLayers) => [...prevLayers, newLayer]);
            };
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(layers);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setLayers(items);
    };

    const handleLayerDeleted = (index) => {
        const deletedLayer = layers[index];
        setUploadedImages(prev => {
            const newSet = new Set(prev);
            newSet.delete(deletedLayer.url);
            return newSet;
        });
        setLayers((prevLayers) => prevLayers.filter((_, i) => i !== index));
    };

    const handleLayerDuplicated = (index) => {
        if (layers.length > 0) {
            const sourceLayer = layers[index];
            const currentState = canvasRef.current?.getObjectState(sourceLayer.id);
            
            const newLayer = {
                ...sourceLayer,
                id: `layer-${Date.now()}`,
                left: (currentState?.left || sourceLayer.left) + 20,
                top: (currentState?.top || sourceLayer.top) + 20,
                scaleX: currentState?.scaleX || sourceLayer.scaleX,
                scaleY: currentState?.scaleY || sourceLayer.scaleY,
                zIndex: layers.length
            };
            
            setLayers(prevLayers => [...prevLayers, newLayer]);
        }
    };

    const handleLayerSelected = (index) => {
        const selectedLayer = layers[index];
        setSelectedLayerId(selectedLayer.id);
        canvasRef.current?.selectObject(selectedLayer.id);
    };

    const handleFlipLayer = (index) => {
        setLayers((prevLayers) => {
            const layerToFlip = prevLayers[index];
            const flippedLayer = { ...layerToFlip, flipped: !layerToFlip.flipped };
            return prevLayers.map((layer, i) => (i === index ? flippedLayer : layer));
        });
    };

    const handleToggleVisibility = (index) => {
        setLayers(prevLayers => prevLayers.map((layer, i) => 
            i === index 
                ? { ...layer, visible: layer.visible === false ? true : false }
                : layer
        ));
    };

    const handleExport = async () => {
        if (canvasRef.current) {
            try {
                const highResCanvas = await canvasRef.current.exportHighRes();
                
                highResCanvas.toBlob((blob) => {
                    const file = new File([blob], 'seamless-pattern-300dpi.png', {
                        type: 'image/png',
                        lastModified: new Date().getTime()
                    });
    
                    saveAs(file, 'seamless-pattern-300dpi.png');
                }, 'image/png', 1.0);
            } catch (error) {
                console.error('Error exporting canvas:', error);
            }
        }
    };

    return (
        <StrictMode>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="app-container">
                    <div className="left-sidebar">
                        <div className="logo-placeholder">
                            <img src={logo} alt="Logo" style={{ width: '150px', height: '750px', marginBottom: '10px' }} />
                        </div>
                    </div>
                    <div className="canvas-container">
                        <div className="canvas-section">
                            <Canvas
                                ref={canvasRef}
                                layers={layers}
                                showGrid={showGrid}
                                backgroundColor={canvasBackground}
                            />
                        </div>
                    </div>
                    <div className="right-sidebar">
                        <div className="export-section">
                            <div style={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                marginBottom: '10px',
                                gap: '20px'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            checked={showGrid}
                                            onChange={(e) => setShowGrid(e.target.checked)}
                                        />
                                        <span className="toggle-slider"></span>
                                    </label>
                                    <span style={{ userSelect: 'none' }}>Show Grid</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <span style={{ userSelect: 'none' }}>Background:</span>
                                    <input
                                        type="color"
                                        value={canvasBackground === 'transparent' ? '#ffffff' : canvasBackground}
                                        onChange={(e) => setCanvasBackground(e.target.value)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <button
                                        onClick={() => setCanvasBackground('transparent')}
                                        style={{
                                            padding: '4px 8px',
                                            background: 'none',
                                            border: '1px solid #666',
                                            color: '#fff',
                                            cursor: 'pointer',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                            <button 
                                onClick={handleExport}
                                className="export-button"
                            >
                                Export High-Res Image (3600x3600)
                            </button>
                        </div>
                        <h2>Upload Image</h2>
                        <ImageUploader onImageUpload={handleImageUpload} />
                        <div className="emoji-section">
                            <h2>Add Emoji</h2>
                            <EmojiPicker onEmojiSelect={handleEmojiSelect} />
                        </div>
                        <LayerManager
                            layers={layers}
                            selectedLayerId={selectedLayerId}
                            onSelectLayer={handleLayerSelected}
                            onDeleteLayer={handleLayerDeleted}
                            onDuplicateLayer={handleLayerDuplicated}
                            onFlipLayer={handleFlipLayer}
                            onToggleVisibility={handleToggleVisibility}
                        />
                    </div>
                </div>
            </DragDropContext>
        </StrictMode>
    );
};

export default App;