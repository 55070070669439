import React, { useState } from 'react';
import EmojiPickerReact from 'emoji-picker-react';
import './EmojiPicker.css';

const EmojiPicker = ({ onEmojiSelect }) => {
    const [showPicker, setShowPicker] = useState(false);

    const handleEmojiClick = (emojiData) => {
        onEmojiSelect(emojiData);
        setShowPicker(false);
    };

    return (
        <div className="emoji-picker-container">
            <button 
                className="emoji-button"
                onClick={() => setShowPicker(!showPicker)}
            >
                Add Emoji 😊
            </button>
            
            {showPicker && (
                <div className="emoji-picker-popup">
                    <div className="emoji-picker-overlay" onClick={() => setShowPicker(false)} />
                    <div className="emoji-picker-content">
                        <EmojiPickerReact onEmojiClick={handleEmojiClick} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmojiPicker;